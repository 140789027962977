import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from '@shopify/react-compose';
import Loader from '~~components/Loader';
import ClickOutside from '~~components/ClickOutside';
import HamburgerIcon from '~~components/HamburgerIcon';
import * as CommonActions from '~~redux/CommonActions';
import Notification from '~~components/Notification';
import { respondSmallerThan } from '~~styles/_variables';
import Sidebar from './Sidebar';
import ResetPwModal from './ResetPwModal';
import PreferenceModal from './PreferenceModal';
import Header from './Header';
import PlayerSearchModal from './PlayerSearchModal';

const Div = styled.div`
  position: relative;
  .clickOutside {
    min-height: auto;
    .iconNav {
      top: 20px;
      right: 3%;
    }
  }

  > div {
    min-height: ${(props) => props.windowInnerHeight};
    box-sizing: border-box;
    &:not(.clickOutside) {
      padding-top: 7%;
    }
  }

  ${respondSmallerThan.tablet_1024`
    .clickOutside{
      .iconNav{
        top: 30px;
      }
    }
  `}

  ${respondSmallerThan.mobile_480`
    .clickOutside{
      .iconNav{
        top: 25px;
      }
    }

    > div {
      &:not(.clickOutside) {
        padding-top: 10%;
      }
    }
  `}
`;

const App = (props) => {
  const { windowInnerHeight, setWindowInnerHeight } = props;
  const [isMenuOpen, setInMenuOpen] = useState(false);
  const [isResetPwModalOpen, setIsResetPwModalOpen] = useState(false);
  const [isPreferModalOpen, setIsPreferModalOpen] = useState(false);
  const [isPlayerSearchModalOpen, setIsPlayerSearchModalOpen] = useState(false);
  const locationPath = useLocation().pathname;

  useEffect(() => {
    function handleResize() {
      setWindowInnerHeight(`${window.innerHeight}px`);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setWindowInnerHeight]);

  useEffect(() => {
    setInMenuOpen(false);
  }, [locationPath]);

  return (
    <>
      <Div windowInnerHeight={windowInnerHeight} isMenuOpen={isMenuOpen}>
        {locationPath.indexOf('auth') === -1 && (
          <ClickOutside onClickOutside={() => setInMenuOpen(false)}>
            <HamburgerIcon isOpen={isMenuOpen} setIsOpen={setInMenuOpen} />
            <Sidebar
              isOpen={isMenuOpen}
              setInMenuOpen={setInMenuOpen}
              setIsResetPwModalOpen={setIsResetPwModalOpen}
              setIsPreferModalOpen={setIsPreferModalOpen}
              setIsPlayerSearchModalOpen={setIsPlayerSearchModalOpen}
            />
          </ClickOutside>
        )}
        {locationPath.indexOf('auth') === -1 && <Header />}
        {props.children}
      </Div>
      <Loader isLoading={props.isLoading} />
      <Notification />

      {/* 選手搜尋 */}
      <PlayerSearchModal
        isOpen={isPlayerSearchModalOpen}
        toggleClose={() => setIsPlayerSearchModalOpen(false)}
        setLoaderStatus={props.setLoaderStatus}
      />

      {/* 變更密碼 */}
      <ResetPwModal
        isOpen={isResetPwModalOpen}
        toggleClose={() => setIsResetPwModalOpen(false)}
        setLoaderStatus={props.setLoaderStatus}
      />

      {/* 喜好設定 */}
      <PreferenceModal
        isOpen={isPreferModalOpen}
        toggleClose={() => setIsPreferModalOpen(false)}
        setLoaderStatus={props.setLoaderStatus}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.common.isLoading,
  windowInnerHeight: state.common.windowInnerHeight,
});

const mapDispatchToProps = {
  setLoaderStatus: CommonActions.setLoaderStatus,
  setWindowInnerHeight: CommonActions.setWindowInnerHeight,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
