import React from 'react';
import { BsPersonCircle } from 'react-icons/bs';
import styled from 'styled-components';

const StyledAvatar = styled.div`
  position: relative;
  width: 100%;
  &::after {
    display: block;
    padding-bottom: 100%;
    content: '';
  }

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  img {
    border-radius: 5px;
    object-fit: contain;
  }
`;

export default function Avatar({ player, className = 'avatar' }) {
  return (
    <StyledAvatar className={className}>
      {player.avatarURL ? <img src={player.avatarURL} alt="avatar" /> : <BsPersonCircle />}
    </StyledAvatar>
  );
}
