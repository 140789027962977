import React from 'react';
import styled, { css } from 'styled-components';
import * as R from 'ramda';
import { respondSmallerThan, respondBiggerThan, COMMON_COLOR } from '~~styles/_variables';

const SmallSrcollBar = (width = 3) => {
  return css`
    ::-webkit-scrollbar {
      width: ${width}px;
      background: none;
      box-shadow: none;
    }
  `;
};

const ButtonStatusStyle = css`
  cursor: pointer;
  user-select: none;
  &:hover {
    filter: brightness(1.1);
  }
`;

const WhiteNeumorphism = (distance = 6, blur = 10) => {
  return css`
    background: ${COMMON_COLOR.WHITE};
    box-shadow: ${distance}px ${distance}px ${blur}px #c9c9c9, -${distance}px -${distance}px ${blur}px #ffffff;
  `;
};

const WhiteNeumorphism2 = (distance = 6, blur = 8, innerDistance = 6, innerBlur = 8) => {
  return css`
    background: ${COMMON_COLOR.WHITE};
    box-shadow: ${distance}px ${distance}px ${blur}px #c9c9c9, -${distance}px -${distance}px ${blur}px #ffffff,
      inset ${innerDistance}px ${innerDistance}px ${innerBlur}px #ffffff,
      inset -${innerDistance}px -${innerDistance}px ${innerBlur}px #c9c9c9;
  `;
};

const TextUnderline = (color = COMMON_COLOR.BRAND_3) => {
  return css`
    ${ButtonStatusStyle};
    color: ${color};
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: ${color};
    text-underline-offset: 1px;
    text-decoration-thickness: 1px;

    &:hover {
      color: ${color};
    }
  `;
};

const TextShadow = (color, distance = 1, blur = 2) => {
  return css`
    text-shadow: ${distance}px 0 ${blur}px ${color}, -${distance}px 0 ${blur}px ${color},
      0 ${distance}px ${blur}px ${color}, 0 -${distance}px ${blur}px ${color},
      ${distance}px ${distance}px ${blur}px ${color}, -${distance}px -${distance}px ${blur}px ${color},
      ${distance}px -${distance}px ${blur}px ${color}, -${distance}px ${distance}px ${blur}px ${color};
  `;
};

export { SmallSrcollBar, ButtonStatusStyle, TextUnderline, WhiteNeumorphism, WhiteNeumorphism2, TextShadow };
