import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getTableData: false,
  createPlayer: false,
  revisePlayer: false,
  removePlayer: false,
  createTeam: false,
  reviseTeam: false,
  removeTeam: false,
  createCoach: false,
  getTeamCoachManaging: false,
  reviseTeamCoach: false,
  removeTeamCoach: false,
  getFacilityCoachManaging: false,
  reviseFacilityCoach: false,
  removeFacilityCoach: false,
};

const DataListResource = {
  getTableData: (tableName) => {
    // console.log('Resource getTableData -----', tableName)
    if (debugStatus.getTableData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }

    if (tableName === 'players') {
      const URL = `/api/players`;
      return new Promise((resolve, reject) => {
        const players = [];
        const fetchData = (cursor) => {
          ApiService.get(!cursor ? URL : `${URL}?cursor=${cursor}`)
            .then(({ data: { players: newPlayers, cursor: newCursor } }) => {
              players.push(...newPlayers);
              if (newPlayers.length > 0 && newCursor !== '') {
                fetchData(newCursor);
                return;
              }

              resolve({ data: players });
            })
            .catch((e) => {
              reject(e);
            });
        };
        fetchData();
      });
    }

    return ApiService.get(`/api/${tableName}`);
  },
  createPlayer: (data) => {
    // console.log('Resource createPlayer -----', data)
    if (debugStatus.createPlayer) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/register`, { data });
  },
  revisePlayer: (data) => {
    // console.log('Resource revisePlayer -----', data)
    if (debugStatus.revisePlayer) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api/players/${data.id}`, { data });
  },
  removePlayer: (id) => {
    // console.log('Resource removePlayer -----', id)
    if (debugStatus.removePlayer) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/players/${id}`);
  },
  createTeam: (data) => {
    // console.log('Resource createTeam -----', data)
    if (debugStatus.createTeam) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/teams`, { data });
  },
  reviseTeam: (data) => {
    // console.log('Resource reviseTeam -----', data)
    if (debugStatus.revisePlayer) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api/teams/${data.id}`, { data });
  },
  removeTeam: (id) => {
    // console.log('Resource removeTeam -----', id)
    if (debugStatus.removeTeam) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/teams/${id}`);
  },
  createCoach: (data) => {
    // console.log('Resource createCoach -----', data)
    if (debugStatus.createCoach) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/register`, { data });
  },
  getTeamCoachManaging: (id) => {
    // console.log('Resource getTeamCoachManaging -----', id)
    if (debugStatus.getTeamCoachManaging) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/team_coaches/${id}/managing`);
  },
  reviseTeamCoach: (data) => {
    // console.log('Resource reviseTeamCoach -----', data)
    if (debugStatus.reviseTeamCoach) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api/team_coaches/${data.id}`, { data });
  },
  removeTeamCoach: (id) => {
    // console.log('Resource removeTeamCoach -----', id)
    if (debugStatus.removeTeamCoach) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/team_coaches/${id}`);
  },
  getFacilityCoachManaging: (id) => {
    // console.log('Resource getFacilityCoachManaging -----', id)
    if (debugStatus.getFacilityCoachManaging) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/facility_coaches/${id}/managing`);
  },
  reviseFacilityCoach: (data) => {
    // console.log('Resource reviseFacilityCoach -----', data)
    if (debugStatus.reviseFacilityCoach) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api/facility_coaches/${data.id}`, { data });
  },
  removeFacilityCoach: (id) => {
    // console.log('Resource removeFacilityCoach -----', id)
    if (debugStatus.removeFacilityCoach) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/facility_coaches/${id}`);
  },
};

export default DataListResource;
