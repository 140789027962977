import React from 'react';
import styled from 'styled-components';
import { HiPlus, HiMinus } from 'react-icons/hi';
import { MdDelete, MdCreate } from 'react-icons/md';
import { IoMdDownload, IoMdEye, IoMdMove, IoMdSearch } from 'react-icons/io';
import { FaCopy, FaCheckCircle, FaUndo } from 'react-icons/fa';
import {
  BsArrowsAngleExpand,
  BsArrowsAngleContract,
  BsEraserFill,
  BsCheckLg,
  BsXLg,
  BsChevronUp,
  BsChevronDown,
} from 'react-icons/bs';
import { AiOutlineClear } from 'react-icons/ai';
import { COMMON_COLOR } from '~~styles/_variables';

const Div = styled.div`
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${COMMON_COLOR.BRAND_1};
  transition: 0.4s ease;

  &.inverseStyle {
    background: transparent;
    color: ${COMMON_COLOR.BRAND_1};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
  }
`;

const BntIcon = (props) => {
  const { className, onClick, disabled, inverse, ...restProps } = props;

  function getClassName() {
    let result = ['btnIcon'];
    if (className) {
      result.push(className);
    }
    if (inverse) {
      result.push('inverseStyle');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  function getIcon() {
    switch (props.iconType) {
      case 'add':
        return <HiPlus />;
      case 'minus':
        return <HiMinus />;
      case 'edit':
        return <MdCreate />;
      case 'delete':
        return <MdDelete />;
      case 'view':
        return <IoMdEye />;
      case 'download':
        return <IoMdDownload />;
      case 'copy':
        return <FaCopy />;
      case 'checked':
        return <FaCheckCircle />;
      case 'expand':
        return <BsArrowsAngleExpand strokeWidth="0.5px" />;
      case 'unExpand':
        return <BsArrowsAngleContract strokeWidth="0.5px" />;
      case 'undo':
        return <FaUndo />;
      case 'clear':
        return <AiOutlineClear />;
      case 'eraser':
        return <BsEraserFill />;
      case 'move':
        return <IoMdMove />;
      case 'V':
        return <BsCheckLg />;
      case 'X':
        return <BsXLg />;
      case 'up':
        return <BsChevronUp />;
      case 'down':
        return <BsChevronDown />;
      case 'search':
        return <IoMdSearch />;
      default:
        break;
    }
  }

  return (
    <Div className={getClassName()} onClick={onClick}>
      {getIcon()}
    </Div>
  );
};

BntIcon.defaultProps = {
  inverse: false,
  className: null,
  onClick: () => {},
  disabled: false,
  iconType: 'add', // add, edit, delete
};

export default BntIcon;
