import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

// https://github.com/fkhadra/react-toastify
const StyledToastContainer = styled(ToastContainer)`
  font-size: 16px;
  min-width: 350px;
  width: auto;

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: #287465;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: #993328;
  }

  .Toastify__toast {
    margin: 5px 0;
    padding: 12px 8px;
    border-radius: 12px;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast-icon {
    font-size: 20px;
  }
`;

const Notification = (props) => {
  return (
    <StyledToastContainer
      position="top-right"
      pauseOnFocusLoss
      draggable
      pauseOnHover
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      transition={Slide}
      theme="colored"
    />
  );
};

export default Notification;
