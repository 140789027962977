import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';
import LogoWithChSVG from '~~static/logo-horizontal.png';
import LogoWhiteWithChSVG from '~~static/logo-horizontal-white.png';
import { USER_ROLE, getUserInfo } from '~~features/Const';

const Head = styled.header`
  background: ${COMMON_COLOR.WHITE};
  .imgLogo {
    position: absolute;
    width: 120px;
    top: 20px;
    left: 4%;
    z-index: 2;
  }

  ${respondSmallerThan.tablet_1024`
    padding: ${({ isLeaderboard }) => (isLeaderboard ? '0' : '6% 12% 4%')};
    .imgLogo{
      top: 30px;
    }
    .title{
      margin: 0 0 3%;
    }
  `}

  ${respondSmallerThan.tablet_880`
    padding: ${({ isLeaderboard }) => (isLeaderboard ? '0' : '6% 8% 8%')};
  `}

  ${respondSmallerThan.mobile_480`
    .imgLogo {
      width: auto;
      height: 36px;
    }
    .title{
      font-size: 30px;
    }
    .conditionZone{
      flex-direction: column;
      >:not(:last-child){
        margin: 0 0 4%
      }
    }
  `}
`;

const Header = (props) => {
  const userInfo = getUserInfo();
  const locationPath = useLocation().pathname;
  const isLeaderboard = locationPath === '/leaderboard';
  const href = isLeaderboard
    ? `https://www.basepara.com`
    : userInfo.role === USER_ROLE.ADMIN
    ? `/dataList`
    : userInfo.role === USER_ROLE.PLAYER
    ? `/player/${userInfo.id}`
    : `/leaderboard`;

  return (
    <Head isLeaderboard={isLeaderboard}>
      <a href={href}>
        <img
          src={isLeaderboard ? LogoWhiteWithChSVG : LogoWithChSVG}
          className="imgLogo"
          alt=""
          // {...(isLeaderboard ? { style: { zIndex: 100 } } : {})}
        />
      </a>
    </Head>
  );
};

export default Header;
