import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'moment/locale/zh-tw';
import 'react-datetime/css/react-datetime.css';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { COMMON_COLOR } from '~~styles/_variables';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  ${InputStyle}

  .dayPicker {
    flex: 1;
    position: unset;
    .dayPickerInput {
      cursor: pointer;
    }
  }

  .rdtPicker {
    width: 100%;
    border: 1px solid ${COMMON_COLOR.BRAND_3};
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    font-size: 14px;
    color: ${COMMON_COLOR.BRAND_2};
    .rdtDays,
    .rdtMonths,
    .rdtYears {
      thead {
        th {
          border-radius: 12px;
          &:hover {
            background: ${COMMON_COLOR.BRAND_4};
          }
          //星期
          &.dow {
            pointer-events: none;
          }
        }
        .rdtSwitch {
          /* flex: 1; */
          width: unset;
        }
        .rdtPrev,
        .rdtNext {
          width: 100px;
        }
      }
      tbody {
        td {
          border-radius: 12px;
          height: 32px;
          &.rdtActive {
            text-shadow: none;
            pointer-events: none;
            color: ${COMMON_COLOR.WHITE};
            background: ${COMMON_COLOR.BRAND_3};
          }
          //上個月和下個月
          &.rdtOld,
          &.rdtNew {
            color: ${COMMON_COLOR.BRAND_5};
          }
          //不合法的日期
          &.rdtDisabled {
            color: #999999;
            &:hover {
              background: none;
            }
          }
          //年份和月份
          &.rdtYear,
          &.rdtMonth {
            height: 50px;
          }
          &:hover {
            background: ${COMMON_COLOR.BRAND_4};
          }
        }
      }
    }

    .rdtYears {
      thead {
        .rdtSwitch {
          pointer-events: none;
        }
      }
    }
  }
`;

const CustomizedDayPicker = (props) => {
  const {
    name,
    fieldLabel,
    value,
    className,
    onChange,
    placeholder,
    errorMsg,
    disabled,
    onBlur,
    onFocus,
    isValidDate,
  } = props;
  const [isReadOnly, setIsReadOnly] = useState(true);
  const triggerField = useRef(null);

  function handleRemove(e) {
    e.preventDefault();
    onChange({ [name]: '' });
  }

  function handleChange(val) {
    let date = moment(val).format('YYYY-MM-DD');

    onChange({ [name]: date });
  }

  function handleFocus() {
    onFocus(name);
    setIsReadOnly(false);
  }

  function handleBlur() {
    onBlur(name);
    setIsReadOnly(true);
  }

  function handleLabelClick() {
    triggerField.current.focus();
  }

  function getClassName() {
    let result = ['dayPickerBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv className={getClassName()} fieldColor={props.fieldColor} fieldBgColor={props.fieldBgColor}>
      <Datetime
        input
        // closeOnSelect
        id={`dayPicker--${name}`}
        className={value ? 'dayPicker hasValue' : 'dayPicker'}
        dateFormat="YYYY-MM-DD"
        value={value}
        onChange={handleChange}
        timeFormat={false}
        isValidDate={isValidDate}
        locale="zh-tw"
        inputProps={{
          className: 'dayPickerInput',
          value: value,
          ref: triggerField,
          name: name,
          placeholder: placeholder,
          onFocus: handleFocus,
          onBlur: handleBlur,
          autoComplete: 'off',
          readOnly: isReadOnly,
        }}
      />
      <label htmlFor={name} className="fieldLabel" onClick={handleLabelClick}>
        {fieldLabel}
      </label>
      {!disabled && !R.isEmpty(value) && (
        <div className="btnFunc" onMouseDown={handleRemove}>
          <BsX />
        </div>
      )}
      {errorMsg && (
        <div className="errorBox">
          <BsExclamationCircle className="icon" />
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

CustomizedDayPicker.defaultProps = {
  fieldLabel: 'Label',
  name: '',
  value: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: '',
  errorMsg: '',
  disabled: false,
  className: '',
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
  isValidDate: () => true,
};

export default CustomizedDayPicker;
