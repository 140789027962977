import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COMMON_COLOR } from '~~styles/_variables';

const loading = keyframes`
  0% {
      transform: scale(1);
      opacity: 1;
  }
  45% {
      transform: scale(0.1);
      opacity: 0.7;
  }
  80% {
      transform: scale(1);
      opacity: 1;
  }
`;

const LoaderDiv = styled.div`
  &.loader-wrapper {
    display: ${(p) => (p.isLoading ? 'block' : 'none')};
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 9999;
      width: 100%;
      height: 100%;
    }
    .pointbase-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 120px;
      opacity: 0.9;
      border-radius: 8px;
      background-color: #ffffffdb;
    }
    .pointbase {
      width: 14px;
      height: 14px;
      display: inline-block;
      margin: 10px;
      border-radius: 100%;
      background: ${COMMON_COLOR.BRAND_1};

      &.point1 {
        animation: ${loading} 0.8s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.15s infinite normal both running;
      }
      &.point2 {
        animation: ${loading} 0.8s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.3s infinite normal both running;
      }
      &.point3 {
        animation: ${loading} 0.8s cubic-bezier(0.2, 0.68, 0.18, 1.08) 0.45s infinite normal both running;
      }
    }
  }
`;

const Loader = (props) => {
  const { type, isLoading } = props;
  return (
    <LoaderDiv className="loader-wrapper" isLoading={isLoading}>
      <div className="loader">
        <div className="pointbase-wrapper">
          <div className="pointbase point1" />
          <div className="pointbase point2" />
          <div className="pointbase point3" />
        </div>
      </div>
    </LoaderDiv>
  );
};

Loader.defaultProps = {};

export default Loader;
