import { css } from 'styled-components';
import { COMMON_COLOR } from '~~styles/_variables';
import { ButtonStatusStyle } from '~~styles/_commonStyle';

const InputStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: 1px solid;
  color: ${(props) => props.fieldColor};
  border-color: ${(props) => props.fieldColor};
  border-radius: 12px;
  position: relative;
  user-select: none;

  &:focus-within {
    border-color: ${COMMON_COLOR.FIELD_FOCUS};
  }

  &.isError {
    border-color: ${COMMON_COLOR.RED_2};
    .styleInput,
    .styleTextArea,
    .styleSelect,
    .input--btnFunc,
    .selector--btnFunc {
      color: ${COMMON_COLOR.RED_2};
    }
  }

  &.disabled {
    /* opacity: 0.7 */
  }

  .fieldLabel {
    position: absolute;
    top: 22%;
    left: 10px;
    transform-origin: 0 0;
    transition: all 0.4s;
  }

  .styleInput,
  .styleTextArea,
  .styleSelect,
  .dayPickerInput {
    -webkit-appearance: none;
    color: ${(props) => props.fieldColor};
    font-size: inherit;
    outline: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    flex: 1;
    background: none;
    letter-spacing: 2px;
    padding: 12px 0 8px 10px;
    z-index: 1;
    width: 100%;

    ::placeholder {
      font-size: 14px;
      color: transparent;
      opacity: 0.5;
      letter-spacing: 1px;
      transition: all 0.5s;
    }

    &:disabled {
      background: none;
      color: ${COMMON_COLOR.BLACK};
      -webkit-text-fill-color: ${COMMON_COLOR.BLACK};
      opacity: 1;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: ${COMMON_COLOR.BLACK};
    }
    &:-internal-autofill-selected {
      border-radius: inherit;
      box-shadow: inset 0px 0px 0px 30px ${COMMON_COLOR.WHITE};
    }
  }

  .styleInput,
  .styleTextArea,
  .dayPicker .dayPickerInput {
    &:focus {
      ::placeholder {
        color: ${(props) => props.fieldColor};
      }
    }
  }

  .styleInput:focus,
  .styleInput:not([value='']),
  .styleTextArea:focus,
  .styleTextArea:not(:empty),
  .rdtOpen,
  .dayPicker.hasValue {
    + .fieldLabel {
      transform: translate(-2px, -20px) scale(0.85);
      /* color: ${COMMON_COLOR.BRAND_1}; */
      background: ${(props) => props.fieldBgColor};
      padding: 0 6px;
      z-index: 1;
      font-size: 16px;
    }
  }

  .iconInField {
    color: ${(props) => props.fieldColor};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 40px;
  }

  .btnFunc {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 100%;
    color: ${(props) => props.fieldColor};
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .errorBox {
    display: flex;
    align-items: center;
    position: absolute;
    top: 104%;
    color: ${COMMON_COLOR.RED_2};
    letter-spacing: 0;
    width: 100%;
    padding-left: 4px;
    border-radius: inherit;
    font-size: 12px;
    .icon {
      margin: 0 8px 0 0;
    }
  }
`;

export default InputStyle;
