import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { AuthResource } from '~~apis/resource';
import { Button } from '~~components/Buttons';
import { Field, PasswordInput } from '~~components/Fields';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '~~components/Modals';
import { handleCheckIsAllFilled, handleCheckIsAllEmpty } from '~~utils/_commonUtils';
import { respondSmallerThan, TOAST_SUCCESS, TOAST_FAIL } from '~~styles/_variables';

const StyleModal = styled(Modal)`
  .modal-content {
    .modal-body {
      padding: 40px 100px;
    }
    .fieldBox {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px 20px;
    }
  }
  ${respondSmallerThan.laptop_1280`
    top: 12%;
  `}
  ${respondSmallerThan.laptop_1280`
    top: 18%;
  `}
  ${respondSmallerThan.mobile_480`
   .modal-content{
    .modal-body{
      padding: 8% 10%;
    }
  }
  `}
`;

const InfoModal = (props) => {
  const { isOpen, toggleClose, setLoaderStatus } = props;

  const [data, setData] = useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errorMsg, setErrorMsg] = useState({});

  useEffect(() => {
    if (isOpen) return;
    setData({});
    setErrorMsg({});
  }, [isOpen]);

  function handleDataChange(value) {
    setData((prev) => ({
      ...prev,
      ...value,
    }));
  }

  function handleBlur(key) {
    if (!data.newPassword) return;
    let msg = '';

    switch (key) {
      case 'newPassword':
        if (data.newPassword.length < 6) {
          setErrorMsg((prev) => ({ ...prev, newPassword: '請輸入至少6碼' }));
        } else if (!data.confirmPassword) {
          msg = '請再次輸入確認密碼';
        } else if (data.newPassword === data.confirmPassword) {
          msg = '確認密碼與新密碼不符';
        } else {
          msg = '';
        }
        break;
      case 'confirmPassword':
        msg = data.newPassword === data.confirmPassword ? '' : '確認密碼與新密碼不符';
        break;
      default:
        break;
    }

    setErrorMsg((prev) => ({ ...prev, confirmPassword: msg }));
  }

  function handleRevise() {
    const info = {
      token: data.password,
      newPassword: data.newPassword,
    };
    setLoaderStatus(true);
    AuthResource.resetPassword(info)
      .then(async (response) => {
        // console.log('response resetPassword -----', response);
        toggleClose();
        setLoaderStatus(false);
        toast('變更成功', TOAST_SUCCESS);
      })
      .catch((error) => {
        // console.log('error -----', error);
        const { data } = error.response;
        toast(data.errorMsgForUser ? `變更失敗：${data.errorMsgForUser}` : '變更失敗', TOAST_FAIL);
        setLoaderStatus(false);
      });
  }

  function handleCheckDisabled() {
    const result = !handleCheckIsAllFilled(data) || !handleCheckIsAllEmpty(errorMsg);
    return result;
  }

  return (
    <StyleModal isOpen={isOpen} modalSize="small">
      <ModalHeader>變更密碼</ModalHeader>
      <ModalBody>
        <Field>
          <PasswordInput
            fieldLabel="原密碼"
            name="password"
            value={data.password}
            placeholder="請輸入原密碼"
            onChange={(val) => handleDataChange(val)}
          />
          <PasswordInput
            fieldLabel="新密碼"
            name="newPassword"
            value={data.newPassword}
            errorMsg={errorMsg.newPassword}
            placeholder="請輸入至少6碼"
            onChange={(val) => handleDataChange(val)}
            onFocus={(key) => setErrorMsg((prev) => ({ ...prev, [key]: '' }))}
            onBlur={() => handleBlur('newPassword')}
          />
          <PasswordInput
            fieldLabel="確認密碼"
            name="confirmPassword"
            value={data.confirmPassword}
            errorMsg={errorMsg.confirmPassword}
            placeholder="請再次輸入新密碼"
            onChange={(val) => handleDataChange(val)}
            onFocus={(key) => setErrorMsg((prev) => ({ ...prev, [key]: '' }))}
            onBlur={() => handleBlur('confirmPassword')}
          />
        </Field>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleClose}>取消</Button>
        <Button onClick={handleRevise} disabled={handleCheckDisabled()}>
          確定
        </Button>
      </ModalFooter>
    </StyleModal>
  );
};

export default InfoModal;
