import React from 'react';
import styled from 'styled-components';
import { Button } from '~~components/Buttons';
import { Modal, ModalBody, ModalFooter } from '~~components/Modals';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';
import { ReactComponent as IconAlert } from '../../static/icon_alert.svg';

const StyledModal = styled(Modal)`
  .modal-content {
    /* Body Style */
    .modal-body {
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 90px 40px 40px;
      font-size: 20px;
      white-space: pre-line;
      .iconAlert {
        position: absolute;
        top: -40px;
        filter: drop-shadow(0px 1px 3px rgb(0 0 0 / 30%));
      }
      p {
        margin: 10px 0 0;
        font-weight: 500;
        color: ${COMMON_COLOR.BRAND_1};
        text-align: center;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: ${COMMON_COLOR.BRAND_1};
        text-underline-offset: 2px;
        /* text-decoration-thickness: 2px; */
      }
      .input-verify {
        margin: 15px 0 0;
      }
    }

    /* Footer Style */
    .modal-footer {
    }
  }

  ${respondSmallerThan.tablet_880`
    .modal-content{
      .modal-body { font-size: 18px }
    }
  `}

  ${respondSmallerThan.mobile_480`
    .modal-content{
      .modal-body {
        padding: 20% 5% 8%;
        .iconAlert{
          width: 80px;
          top: -48px;
        }
        p{
          margin: 2% 0 0;
        }
      }
    }
  `}

  ${respondSmallerThan.mobile_375`
    .modal-content{
      .modal-body { font-size: 16px }
    }
  `}
`;

const AlertModal = (props) => {
  const { children, modalSize, toggleClose, onConfirm, ...restProps } = props;

  function handleConfirm() {
    onConfirm();
    toggleClose();
  }

  return (
    <StyledModal contentClassName={modalSize} {...restProps}>
      <ModalBody>
        <IconAlert className="iconAlert" />
        {children}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleClose}>取消</Button>
        <Button onClick={handleConfirm}>確定</Button>
      </ModalFooter>
    </StyledModal>
  );
};

AlertModal.defaultProps = {
  /** 寬度 */
  modalSize: 'small',
  onConfirm: () => {},
};

export default AlertModal;
