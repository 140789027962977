import React from 'react';
import styled, { css } from 'styled-components';
import { IoMdLogIn, IoMdLogOut } from 'react-icons/io';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '~~components/Buttons';
import { COMMON_COLOR, respondSmallerThan } from '~~styles/_variables';
import LogoSVG from '~~static/logo-horizontal-white.png';
import { USER_ROLE } from '~~features/Const';
import { useLogin } from '../../hooks/useLogin';

const BlueNeumorphism = css`
  background: ${COMMON_COLOR.BRAND_2};
  color: ${COMMON_COLOR.WHITE};
  width: 50%;
  box-shadow: 4px 4px 6px #4d5e6d, -4px -4px 6px #8392a2;
  &:hover {
    box-shadow: 2px 2px 2px #4d5e6d, -2px -2px 2px #8392a2;
  }
`;

const Div = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  background: #385169b3;
  padding: 80px 0 20px;
  transition: transform 0.6s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(150%)')};
  backdrop-filter: blur(3px);
  width: 300px;
  height: 100%;
  box-shadow: 2px 0px 15px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .imgLogo {
    width: 60%;
    margin: 0 0 40px;
    padding: 12px 18px;
    border-radius: 12px;
    background: #617486;
    box-shadow: 2px 2px 2px #4d5e6d, -2px -2px 2px #8392a2;
  }

  .styleButton {
    ${BlueNeumorphism}
  }

  .styleButton,
  > a {
    margin: 0 0 20px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${respondSmallerThan.tablet_1024`
    width: 50%;
  `}

  ${respondSmallerThan.mobile_480`
    width: 100%;
  `}
`;

const DivBG = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #385169ee;

  transition: transform 0.6s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(150%)')};
`;

const Sidebar = (props) => {
  const { isOpen, setInMenuOpen, setIsResetPwModalOpen, setIsPreferModalOpen, setIsPlayerSearchModalOpen } = props;
  const history = useHistory();

  const { isLogin, userInfo } = useLogin();

  const locationPath = useLocation().pathname;
  const isLeaderboard = locationPath === '/leaderboard';
  const href = isLeaderboard
    ? `https://www.basepara.com`
    : userInfo?.role === USER_ROLE.ADMIN
    ? `/dataList`
    : userInfo?.role === USER_ROLE.PLAYER
    ? `/player/${userInfo.id}`
    : `/leaderboard`;

  function handleLogin() {
    history.push('/auth/login');
  }

  function handleLogout() {
    localStorage.clear();
    history.push('/auth/login');
  }

  function handleRevisePwClick() {
    setIsResetPwModalOpen(true);
    setInMenuOpen(false);
  }

  function handleRevisePreferClick() {
    setIsPreferModalOpen(true);
    setInMenuOpen(false);
  }

  function handleRevisePlayerClick() {
    setIsPlayerSearchModalOpen(true);
    setInMenuOpen(false);
  }

  return (
    <>
      <DivBG isOpen={isOpen} />
      <Div isOpen={isOpen}>
        <div className="bg" />
        <a className="imgLogo" href={href}>
          <img src={LogoSVG} alt="" />
        </a>
        {isLogin && userInfo.role !== USER_ROLE.PLAYER && (
          <>
            <Button>
              <a href="/dataList">管理列表</a>
            </Button>
            <Button>
              <a href="/compare">層級比較</a>
            </Button>
            <Button>
              <a href="/jump">壓測板</a>
            </Button>
            <Button onClick={handleRevisePlayerClick}>選手搜尋</Button>
          </>
        )}
        {isLogin ? (
          <>
            <Button onClick={handleRevisePreferClick}>偏好設定</Button>
            <Button onClick={handleRevisePwClick}>變更密碼</Button>
            <Button onClick={handleLogout}>
              登出
              <IoMdLogOut className="icon" />
            </Button>
          </>
        ) : (
          <Button onClick={handleLogin}>
            登入
            <IoMdLogIn className="icon" />
          </Button>
        )}
      </Div>
    </>
  );
};

export default Sidebar;
