import React, { useEffect, useRef } from 'react';

const ClickOutside = (props) => {
  const { children, excludeClass, onClickOutside } = props;
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  function handleClickOutside(event) {
    const node = event.target;
    if (typeof node.className !== 'string') return;
    const classList = node.className.split(' ');
    const isAnyCommon = classList.some((item) => excludeClass.includes(item));

    if (isAnyCommon) return;
    if (wrapperRef.current && !wrapperRef.current.contains(node)) {
      onClickOutside();
    }
  }

  return (
    <div ref={wrapperRef} className="clickOutside">
      {children}
    </div>
  );
};

ClickOutside.defaultProps = {
  children: '',
  excludeClass: [],
  onClickOutside: () => console.log('onClickOutside!'),
};

export default ClickOutside;
