import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PlayerResource } from '~~apis/resource';
import { Button, BtnIcon } from '~~components/Buttons';
import { Field, Input } from '../../components/Fields';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '~~components/Modals';
import { respondSmallerThan } from '~~styles/_variables';
import Avatar from '../../components/Avatar';

const StyleModal = styled(Modal)`
  .modal-content {
    .modal-body {
      padding: 40px 100px;
    }
    .fieldBox {
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }
  ${respondSmallerThan.laptop_1280`
    top: 12%;
  `}
  ${respondSmallerThan.laptop_1280`
    top: 18%;
  `}
  ${respondSmallerThan.mobile_480`
   .modal-content{
    .modal-body{
      padding: 8% 10%;
    }
  }
  `}
`;

const PlayerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0.6rem;
  margin: 0 0 0.6rem;
  border-bottom: 1px solid #e1e1e1;
  color: #545454;
  .avatar {
    width: 36px;
    margin-right: 12px;
  }
`;

const PlayerSearchModal = (props) => {
  const { isOpen, toggleClose, setLoaderStatus } = props;

  const [name, setName] = useState('');
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    if (!isOpen) return;
    setName('');
  }, [isOpen]);

  const handlePlayerSearch = () => {
    const query = name.trim();
    if (query.length < 2) {
      return;
    }

    setPlayers([]);
    setLoaderStatus(true);
    PlayerResource.listPlayer(query)
      .then(({ data }) => {
        console.log(data);
        setPlayers(data.players);
      })
      .finally(() => {
        setLoaderStatus(false);
      });
  };

  return (
    <StyleModal isOpen={isOpen} modalSize="small">
      <ModalHeader handleGoBack={toggleClose}>選手搜尋</ModalHeader>
      <ModalBody>
        <Field>
          <Input name="name" value={name} onChange={(val) => setName(val['name'])} fieldLabel="選手名稱" />
          <BtnIcon iconType="search" onClick={handlePlayerSearch} disabled={name.length < 2} />
        </Field>
        <p>
          <small>*至少兩字</small>
        </p>
        <hr />
        {players.map((player) => (
          <a key={player.id} className="nodec" href={`/player/${player.id}`}>
            <PlayerItem>
              <Avatar player={player} />
              <span>
                {player.name}／{player.teamName ?? '-'}
              </span>
            </PlayerItem>
          </a>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleClose}>返回</Button>
      </ModalFooter>
    </StyleModal>
  );
};

export default PlayerSearchModal;
