import React from 'react';
import styled from 'styled-components';
import { COMMON_COLOR } from '~~styles/_variables';
import { WhiteNeumorphism } from '~~styles/_commonStyle';
import { respondSmallerThan } from '~~styles/_variables';

const Div = styled.div`
  ${WhiteNeumorphism(4, 6)}
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  border-radius: 12px;
  padding: 8px 0;
  color: ${(props) => props.textColor};
  background: ${(props) => props.bgColor};
  transition: 0.5s ease all;
  cursor: pointer;
  user-select: none;
  .icon {
    margin: 0 2px;
  }

  &.round {
    border-radius: 100px;
  }

  &.inverseStyle {
    color: ${(props) => props.bgColor};
    background: transparent;
    box-shadow: none;
    &:hover {
      opacity: 0.7;
      box-shadow: none;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
    box-shadow: 2px 2px 3px #c9c9c9, -2px -2px 3px #ffffff;
  }

  &:hover {
    box-shadow: 2px 2px 3px #c9c9c9, -2px -2px 3px #ffffff;
  }
  /* &:active{
    box-shadow: 2px 2px 3px #c9c9c9, -2px -2px 3px #ffffff;
  } */

  &.btnWithIcon {
    .icon {
      margin: 0 2px;
      font-size: 18px;
    }
    &.switch {
      .icon {
        display: none;
      }
    }
    &:hover {
      box-shadow: 1px 1px 1px #c9c9c9, -1px -1px 1px #ffffff;
    }
  }

  ${respondSmallerThan.mobile_480`
     &.btnWithIcon{
      min-width: 40px;
      span{ display: none }
      &.switch{
        .icon{ display: unset }
      }
     }
  `}
`;

const Button = (props) => {
  const { children, className, onClick, disabled, inverse, round, ...restProps } = props;

  function getClassName() {
    let result = ['styleButton'];
    if (className) {
      result.push(className);
    }
    if (round) {
      result.push('round');
    }
    if (inverse) {
      result.push('inverseStyle');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <Div className={getClassName()} onClick={onClick} {...restProps}>
      {children}
    </Div>
  );
};

Button.defaultProps = {
  inverse: false,
  children: '',
  className: null,
  onClick: () => {},
  bgColor: COMMON_COLOR.WHITE,
  textColor: COMMON_COLOR.BLACK,
  round: false,
  disabled: false,
};

export default Button;
