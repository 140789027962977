import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { COMMON_COLOR, respondBiggerThan, respondSmallerThan } from '~~styles/_variables';
import { WhiteNeumorphism } from '~~styles/_commonStyle';

const fullModalStyle = () => {
  return css`
    width: 100vw;
    margin: 0;
  `;
};

const XXLargeModalStyle = () => {
  return css`
    max-width: 1000px;
    margin: 5% auto;
    ${respondSmallerThan.laptop_1440`
       margin: 3% auto;
    `}
    ${respondSmallerThan.laptop_1280`
      // margin: 3% auto;
    `}
    ${respondSmallerThan.tablet_1024`

      margin: 5% auto;
    `}
    ${respondSmallerThan.tablet_880`
      margin: 3% auto;
    `}
    ${respondSmallerThan.mobile_480`
      // top: 10%;
    `}
  `;
};

const ExtrLargeModalStyle = () => {
  return css`
    max-width: 750px;
    margin: 5% auto;
    ${respondSmallerThan.laptop_1440`
       margin: 3% auto;
    `}
    ${respondSmallerThan.laptop_1280`
      // margin: 3% auto;
    `}
    ${respondSmallerThan.tablet_1024`
      margin: 15% auto 6%;
    `}
    ${respondSmallerThan.tablet_880`

    `}
    ${respondSmallerThan.tablet_768`
      margin: 8% auto 5%;
    `}
    ${respondSmallerThan.mobile_480`
      // top: 10%;
    `}
  `;
};

const LargeModalStyle = () => {
  return css`
    top: 0;
    margin: 5% auto;
    max-width: 600px;
    ${respondSmallerThan.laptop_1550`
      margin: 3% auto;
    `}
    ${respondSmallerThan.tablet_1024`
      margin: 15% auto 6%;
    `}
    ${respondSmallerThan.tablet_768`
      margin: 8% auto 5%;
    `}
    ${respondSmallerThan.mobile_480`

    `}
    ${respondSmallerThan.mobile_375`

    `}
  `;
};

const SmallModalStyle = () => {
  return css`
    top: 18%;
    margin: 0 auto;
    max-width: 500px;
    ${respondSmallerThan.tablet_880`
      max-width: 450px;
    `}
    ${respondSmallerThan.mobile_480`
    `}
  `;
};

const StyledModal = styled(Modal)`
  ${(props) => getModalBaseStyle(props.contentClassName)}
  .modal-content {
    border-radius: 12px;
    border: none;
    background: ${COMMON_COLOR.WHITE};
    font-size: inherit;

    &.full {
      width: 100vw;
      min-height: 100vh;
    }

    &.extrLarge {
    }

    &.large {
    }

    &.small {
    }

    /* Header Style */
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: ${COMMON_COLOR.BRAND_3};
      background: ${COMMON_COLOR.WHITE};
      padding: 12px 0;
      letter-spacing: 2px;
      border-radius: 12px 12px 0 0;
      position: sticky;
      top: 0;
      z-index: 3;

      .modal-title {
        font-size: 22px;
        .btnBack {
          ${WhiteNeumorphism(2, 4)}
          position: absolute;
          left: 20px;
          min-width: 50px;
          padding: 5px 0;
          &:hover {
            ${WhiteNeumorphism(1, 2)}
          }
        }
      }
    }

    /* Body Style */
    .modal-body {
      padding: 40px 50px 20px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    /* Footer Style */
    .modal-footer {
      justify-content: center;
      align-items: center;
      padding: 20px 0 30px;
      border: none;
      .styleButton {
        margin: 0 30px;
      }
    }
  }
  ${respondSmallerThan.tablet_880`
    max-width: 90%;
  `}

  ${respondSmallerThan.mobile_480`
    .modal-content{
      .modal-header {
        padding: 3% 0;
        .modal-title{
          font-size: 18px;
        }
      }
      .modal-body{
        padding: 8% 10% 4%;
        font-size: 14px;
      }
      .modal-footer {
        .styleButton {
          margin: 0 3%;
          width: 40%;
          min-width: unset;
        }
      }
    }
  `}
`;

function getModalBaseStyle(modalSize) {
  switch (modalSize) {
    case 'small':
      return SmallModalStyle;
    case 'large':
      return LargeModalStyle;
    case 'extrLarge':
    case 'extraLarge':
      return ExtrLargeModalStyle;
    case 'XXL':
      return XXLargeModalStyle;
    case 'full':
      return fullModalStyle;
    default:
      break;
  }
}

const CustomizedModal = (props) => {
  const { children, modalSize, ...restProps } = props;

  return (
    <StyledModal contentClassName={modalSize} {...restProps}>
      {children}
    </StyledModal>
  );
};

CustomizedModal.propTypes = {
  modalSize: PropTypes.oneOf(['small', 'large', 'extrLarge', 'extraLarge', 'XXL', 'full']),
};

CustomizedModal.defaultProps = {
  /** 寬度 */
  modalSize: 'large',
};

export default CustomizedModal;
