import { handleActions } from 'redux-actions';
import { actionTypes } from './CommonActions';

const initialState = {
  isLoading: false,
  windowInnerHeight: '100vh',
  userInfo: {},
};

export default handleActions(
  {
    [actionTypes.COMMON___SET_LOADER_STATUS]: (state, payload) => {
      const { status } = payload;
      return {
        ...state,
        isLoading: status,
      };
    },

    [actionTypes.COMMON___SET_WINDOW_INNERHEIGHT]: (state, payload) => {
      const { val } = payload;
      return {
        ...state,
        windowInnerHeight: val,
      };
    },

    [actionTypes.COMMON___UPDATE_USER_INFO]: (state, payload) => {
      const { data } = payload;
      // console.log('Reducer updateUserInfo ::::', data)
      return {
        ...state,
        userInfo: data,
      };
    },
  },
  initialState,
);
