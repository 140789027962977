import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { COMMON_COLOR } from '~~styles/_variables';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  ${InputStyle}
`;

const Input = (props) => {
  const {
    name,
    fieldLabel,
    value,
    type,
    className,
    onChange,
    placeholder,
    errorMsg,
    disabled,
    onBlur,
    onFocus,
    ...restProps
  } = props;
  const [isReadOnly, setIsReadOnly] = useState(true);
  const triggerField = useRef(null);

  function handleRemove(e) {
    e.preventDefault();
    onChange({ [name]: '' });
  }

  function handleChange(e) {
    const val = e.target.value;
    const key = e.target.name;

    onChange({ [key]: val });
  }

  function handleFocus() {
    onFocus(name);
    setIsReadOnly(false);
  }

  function handleBlur() {
    onBlur(name);
    setIsReadOnly(true);
  }

  function handleLabelClick() {
    triggerField.current.focus();
  }

  function getClassName() {
    let result = ['inputBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv
      className={getClassName()}
      fieldColor={props.fieldColor}
      fieldBgColor={props.fieldBgColor}
      {...restProps}
    >
      <input
        // {...props}
        ref={triggerField}
        id={`input--${name}`}
        name={name}
        value={value}
        type={type}
        className="styleInput"
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        // readOnly={isReadOnly}
      />
      <label htmlFor={name} className="fieldLabel" onClick={handleLabelClick}>
        {fieldLabel}
      </label>
      {!disabled && !R.isEmpty(value) && (
        <div className="btnFunc" onMouseDown={handleRemove}>
          <BsX />
        </div>
      )}
      {errorMsg && (
        <div className="errorBox">
          {/* <BsExclamationCircle className='icon' /> */}
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

Input.defaultProps = {
  type: 'text',
  fieldLabel: 'Label',
  name: '',
  value: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: '',
  errorMsg: '',
  disabled: false,
  className: '',
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
};

export default Input;
