import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getLeaderboard: false,
};

const LeaderboardResource = {
  getLeaderboard: (data) => {
    // console.log('Resource getLeaderboard -----', data)
    if (debugStatus.getLeaderboard) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(
      `/api/leaderboard?stats=${data.stats}&baseline=${data.baseline}&column=${data.column}&age=${encodeURIComponent(
        data.age,
      )}&level=${data.level}`,
    );
  },
};

export default LeaderboardResource;
