import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { Button, BntIcon } from '~~components/Buttons';
import { WhiteNeumorphism, WhiteNeumorphism2 } from '~~styles/_commonStyle';
import { COMMON_COLOR } from '~~styles/_variables';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: none;
  color: ${(props) => props.fieldColor};
  position: relative;
  user-select: none;

  &.isError {
    .fileBox {
      color: ${COMMON_COLOR.WHITE};
      background: #c85e5e;
      box-shadow: 1px 1px 4px #c9c9c9, -1px -1px 4px #ffffff, inset 2px 2px 2px #e57a7a, inset -2px -2px 2px #a54a4a;
      .btnDelete {
        color: #851e1e;
      }
    }
  }

  input {
    display: none;
  }

  .fieldLabel {
    position: absolute;
    transform: scale(0.85);
    top: -10px;
    left: -2px;
    font-size: 16px;
  }

  .btnSelect {
    ${WhiteNeumorphism(2, 4)};
    min-width: 100px;
    margin: 15px 0 0 2px;
    font-size: 14px;
    &:hover {
      ${WhiteNeumorphism(1, 2)};
    }
  }

  .fileBox {
    ${WhiteNeumorphism2(1, 4, 2, 2)}
    display: flex;
    padding: 12px 10px;
    border-radius: 12px;
    margin: 12px 0 0;
    .btnDelete {
      margin: 0 0 0 10px;
      font-size: 20px;
    }
  }

  .errorBox {
    display: flex;
    align-items: center;
    position: absolute;
    top: 104%;
    color: ${COMMON_COLOR.RED};
    letter-spacing: 0;
    width: 100%;
    padding-left: 4px;
    border-radius: inherit;
    font-size: 12px;
    .icon {
      margin: 0 8px 0 0;
    }
  }
`;

const Upload = (props) => {
  const { name, fieldLabel, className, onChange, errorMsg, disabled, accept } = props;
  const uploadRef = useRef(null);
  const [fileName, setFileName] = useState('');

  function handleRemove(e) {
    e.preventDefault();
    setFileName('');
    onChange({ [name]: '' });
  }

  function handleFileChange(e) {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;
    const { name: filename, size: fileSize } = file;

    setFileName(filename);
    onChange({ [name]: file });
  }

  function handleClickUpload() {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  }

  function getClassName() {
    let result = ['uploadBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv className={getClassName()} fieldColor={props.fieldColor} fieldBgColor={props.fieldBgColor}>
      <input
        ref={uploadRef}
        id={`upload--${name}`}
        key={fileName}
        type="file"
        name={name}
        multiple={false}
        accept={accept}
        onChange={handleFileChange}
      />
      <label htmlFor={name} className="fieldLabel">
        {fieldLabel}
      </label>
      {fileName ? (
        <div className="fileBox">
          <div>{fileName}</div>
          <BntIcon inverse className="btnDelete" iconType="delete" onClick={handleRemove} />
        </div>
      ) : (
        <Button className="btnSelect" onClick={handleClickUpload} disabled={props.disabled}>
          請選擇檔案
        </Button>
      )}

      {errorMsg && (
        <div className="errorBox">
          {/* <BsExclamationCircle className='icon' /> */}
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

Upload.defaultProps = {
  fieldLabel: 'Label',
  name: '',
  onChange: () => {},
  errorMsg: '',
  disabled: false,
  className: '',
  accept: '',
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
};

export default Upload;
