import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  login: false,
  forgotPassword: false,
  resetPassword: false,
  resetOtherUserPassword: false,
};

const AuthResource = {
  login: (data) => {
    // console.log('Resource login -----', data)
    if (debugStatus.login) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/login`, { data });
  },

  forgotPassword: (data) => {
    // console.log('Resource forgotPassword -----', data)
    if (debugStatus.forgotPassword) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/forgot_password`, { data });
  },

  resetPassword: (data) => {
    // console.log('Resource resetPassword -----', data)
    if (debugStatus.resetPassword) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/reset_password`, { data });
  },

  resetOtherUserPassword: (userID) => {
    // console.log('Resource resetOtherUserPassword -----', userID)
    if (debugStatus.resetOtherUserPassword) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api//users/${userID}/reset_password`);
  },
};

export default AuthResource;
