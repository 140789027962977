import React from 'react';
import { Redirect } from 'react-router-dom';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { TOAST_SUCCESS, TOAST_FAIL } from '~~styles/_variables';

const USER_ROLE = {
  ADMIN: 'Admin',
  TEAM_COACH: 'TeamCoach',
  FACILITY_COACH: 'FacilityCoach',
  PLAYER: 'Player',
};

function allowRoles(roles) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  if (R.isEmpty(userInfo)) {
    toast('請重新登入', TOAST_FAIL);
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
        }}
      />
    );
  }
  const userRole = userInfo.role;
  const result = roles.includes(userRole);

  return result;
}

function getUserInfo() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
  return userInfo;
}

export { USER_ROLE, allowRoles, getUserInfo };
