import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { COMMON_COLOR } from '~~styles/_variables';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  ${InputStyle}
  .styleInput {
    padding: 10px 0;
    ::placeholder {
      color: ${(props) => props.fieldColor};
    }
  }
`;

const InputWithIcon = (props) => {
  const { name, value, type, iconInField, className, onChange, placeholder, errorMsg, disabled, onBlur, onFocus } =
    props;
  const [isReadOnly, setIsReadOnly] = useState(true);

  function handleRemove(e) {
    e.preventDefault();
    onChange({ [name]: '' });
  }

  function handleChange(e) {
    const val = e.target.value;
    const key = e.target.name;

    onChange({ [key]: val });
  }

  function handleFocus() {
    onFocus(name);
    setIsReadOnly(false);
  }

  function handleBlur() {
    onBlur(name);
    setIsReadOnly(true);
  }

  function getClassName() {
    let result = ['inputBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv className={getClassName()} fieldColor={props.fieldColor} fieldBgColor={props.fieldBgColor}>
      {iconInField && <div className="iconInField">{iconInField}</div>}
      <input
        // {...props}
        id={`input--${name}`}
        name={name}
        value={value}
        type={type}
        className="styleInput"
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="new-password"
        // readOnly={isReadOnly}
      />
      {!disabled && !R.isEmpty(value) && (
        <div className="btnFunc" onMouseDown={handleRemove}>
          <BsX />
        </div>
      )}
      {errorMsg && (
        <div className="errorBox">
          {/* <BsExclamationCircle className='icon' /> */}
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

InputWithIcon.defaultProps = {
  type: 'text',
  name: '',
  value: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: '',
  errorMsg: '',
  disabled: false,
  className: '',
  iconInField: null,
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
};

export default InputWithIcon;
