import { useEffect } from 'react';
import * as R from 'ramda';
import _get from 'lodash/get';

const randomBetween = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;

// fakeApiHelper  ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// responseType: success | fail | random | inOrder
let count = 1;
const fakeApiHelper = (
  interval = 1000,
  responseType = 'success',
  successResponse,
  ErrorResponse = 'SOMETHING_WRONG',
) => {
  return new Promise((resolve, reject) => {
    // 一次成功，一次失敗
    if (responseType === 'inOrder') {
      if (count % 2 === 1) {
        const axiosFormat = { data: successResponse };
        setTimeout(() => resolve(axiosFormat), interval);
      } else {
        const axiosFormat = { data: ErrorResponse };
        setTimeout(() => reject(axiosFormat), interval);
      }
      count += 1;
    }

    // 隨機的回傳成功或失敗
    if (responseType === 'random') {
      const random = randomBetween(1, 100);
      if (random % 2 === 1) {
        const axiosFormat = { data: successResponse };
        setTimeout(() => resolve(axiosFormat), interval);
      } else {
        const axiosFormat = { data: ErrorResponse };
        setTimeout(() => reject(axiosFormat), interval);
      }
      count += 1;
    }

    if (responseType === 'success') {
      const axiosFormat = { data: successResponse };
      setTimeout(() => resolve(axiosFormat), interval);
    }
    if (responseType === 'fail') {
      const axiosFormat = { data: ErrorResponse };
      setTimeout(() => reject(axiosFormat), interval);
    }
  });
};

// 驗證電子信箱格式 ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
function handleEmailValid(value, isRequired = false) {
  let result = {
    isValid: true,
    msg: '',
  };

  /**
   * 中間一定要有一個@
   * '@'前後可以是英文, 數字, 符號.或_或-
   * '@'前後至少1個字，至多100個字
   */
  const regex = /^([\w\.\-\_]){1,100}\@([\w\.\-]){1,100}$/;
  const isValid = regex.test(value);

  if (!isRequired && !value) {
    result = {
      isValid: true,
      msg: '',
    };
  } else if (isRequired && !value) {
    result = {
      isValid: false,
      msg: ' 請輸入電子信箱',
    };
  } else if (!isValid) {
    result = {
      isValid: false,
      msg: '電子信箱格式錯誤',
    };
  }

  return result;
}

// object是否全是空值  ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
function handleCheckIsAllEmpty(obj) {
  let result = Object.keys(obj).every((item) => obj[item] === '' || R.isNil(obj[item]) || R.isEmpty(obj[item]));
  return result;
}

// object是否全都有值  ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
function handleCheckIsAllFilled(obj) {
  let result = Object.keys(obj).every((item) => obj[item] !== '' && obj[item] !== undefined);
  return result;
}

// 多個api
async function handleMultiApi(...func) {
  return await Promise.all([...func]);
}

// debounce
const useDebouncedEffect = (effect, deps, delay = 600) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export {
  fakeApiHelper,
  handleEmailValid,
  handleCheckIsAllEmpty,
  handleCheckIsAllFilled,
  handleMultiApi,
  useDebouncedEffect,
};
