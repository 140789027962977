import React from 'react';
import { toast } from 'react-toastify';
import { IoCloseCircle, IoCheckmarkCircle } from 'react-icons/io5';
import { css } from 'styled-components';

const breakpoints = {
  desktop_2100: '2100px',
  desktop_1800: '1800px',
  laptop_1550: '1550px',
  laptop_1440: '1440px',
  laptop_1370: '1370px',
  laptop_1280: '1280px',
  tablet_1024: '1024px',
  tablet_880: '880px',
  tablet_768: '768px',
  mobile_480: '480px',
  mobile_430: '430px',
  mobile_375: '375px',
};

// 1536*747
// 1229*577

// https://tobbelindstrom.com/blog/how-to-create-a-breakpoint-mixin-with-styled-components/
const respondSmallerThan = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

const respondBiggerThan = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media screen and (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

const COMMON_COLOR = {
  BLACK: '#545454',
  WHITE: '#f0f2f4',
  RED: '#c23e3e',
  RED_2: '#eb2e2e',
  GREEN: '#15bd44',
  BRAND_1: '#004588',
  BRAND_2: '#385169',
  BRAND_3: '#7c97b1',
  BRAND_4: '#dce3e9',
  BRAND_5: '#7d9fc0',

  //correct
  BRAND_SECONDARY: '#1B305A',
  // BRAND_3: '#80a7ce',

  FIELD_FOCUS: '#5097c5',
  FIELD_FOCUS_LABEL: '#0471db',

  BOARD_BLUE_1: '#43b0f8',
  // BOARD_BLUE_1: '#43d3f8',
};

const TOAST_SUCCESS = {
  type: toast.TYPE.SUCCESS,
  autoClose: 2500,
  icon: <IoCheckmarkCircle />,
};

const TOAST_WARNING = {
  type: toast.TYPE.WARNING,
  autoClose: 2500,
  icon: <IoCloseCircle />,
};

const TOAST_FAIL = {
  type: toast.TYPE.ERROR,
  autoClose: 5000,
  icon: <IoCloseCircle />,
};

export { respondSmallerThan, respondBiggerThan, COMMON_COLOR, TOAST_SUCCESS, TOAST_WARNING, TOAST_FAIL };
