import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getTeamInfo: false,
};

const TeamResource = {
  getTeamInfo: (data) => {
    // console.log('Resource getTeamInfo -----', data)
    if (debugStatus.getTeamInfo) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/teams/${data.teamId}`);
  },
};

export default TeamResource;
