export const actionTypes = {
  COMMON___SET_LOADER_STATUS: 'COMMON___SET_LOADER_STATUS',
  COMMON___SET_WINDOW_INNERHEIGHT: 'COMMON___SET_WINDOW_INNERHEIGHT',
  COMMON___UPDATE_USER_INFO: 'COMMON___UPDATE_USER_INFO',
};

export function setLoaderStatus(status) {
  return {
    type: actionTypes.COMMON___SET_LOADER_STATUS,
    status,
  };
}

export function setWindowInnerHeight(val) {
  return {
    type: actionTypes.COMMON___SET_WINDOW_INNERHEIGHT,
    val,
  };
}

export function updateUserInfo(data) {
  return {
    type: actionTypes.COMMON___UPDATE_USER_INFO,
    data,
  };
}
