import { useEffect, useState } from 'react';
import * as R from 'ramda';

export const useLogin = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const check = () => {
      const userInfoStr = localStorage.getItem('userInfo');
      const uInfo = JSON.parse(userInfoStr) || {};
      setUserInfo(uInfo);
      setIsLogin(!R.isEmpty(uInfo));
    };

    check();
    const id = setInterval(() => {
      check();
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  return { isLogin, userInfo };
};
