import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getPlayerInfo: false,
  getGraphicData: false,
  getGraphicRawData: false,
  createData: false,
  rawData: false,
  downloadData: false,
  removeData: false,
  previewReport: false,
  createReport: false,
  getReportList: false,
  removeReport: false,
  getReport: false,
  getTrainingAdvice: false,
  createTrainingCalendar: false,
  createTraining: false,
  getTrainingList: false,
  removeTraining: false,
  getComparisonRaw: false,
};

const PlayerResource = {
  listPlayer: (query) => {
    return ApiService.get(`/api/players?q=${encodeURIComponent(query)}`);
  },

  getPlayerInfo: (data) => {
    // console.log('Resource getPlayerInfo -----', data)
    if (debugStatus.getPlayerInfo) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${data.playerId}`);
  },

  getGraphicData: (data) => {
    // console.log('Resource getGraphicData -----', data)
    if (debugStatus.getGraphicData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }

    const query = `?after=${data.after}&before=${data.before}`;
    return ApiService.get(`/api/players/${data.playerId}/${data.type}${query}`);
  },

  getGraphicRawData: (data) => {
    // console.log('Resource getGraphicRawData -----', data)
    if (debugStatus.getGraphicRawData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }

    const query = `?after=${data.after}&before=${data.before}`;
    return ApiService.get(`/api/players/${data.playerId}/${data.type}/raw${query}`);
  },

  createData: (playerId, dataType, resource, data) => {
    // console.log('Resource createData -----', playerId, dataType, resource, data)
    if (debugStatus.createData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/players/${playerId}/${dataType}/${resource}`, { data });
  },

  rawData: (playerId, dataType, dataId) => {
    // console.log('Resource rawData -----', playerId, dataType, dataId)
    if (debugStatus.rawData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${playerId}/${dataType}/${dataId}/raw`);
  },

  downloadData: (playerId, dataType, dataId) => {
    // console.log('Resource downloadData -----', playerId, dataType, dataId)
    if (debugStatus.downloadData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${playerId}/${dataType}/${dataId}/download`, { responseType: 'blob' });
  },

  removeData: (playerId, dataType, dataId, password) => {
    // console.log('Resource removeData -----', playerId, dataType, dataId)
    if (debugStatus.removeData) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/players/${playerId}/${dataType}/${dataId}`, { data: { verify: password } });
  },

  previewReport: (playerId, data) => {
    // console.log('Resource previewReport -----', playerId, data);
    if (debugStatus.previewReport) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/players/${playerId}/report_preview`, { data });
  },

  createReport: (playerId, data) => {
    // console.log('Resource createReport -----', playerId, data);
    if (debugStatus.createReport) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/players/${playerId}/reports`, { data });
  },

  updateReport: (reportId, data) => {
    return ApiService.put(`/api/reports/${reportId}`, { data });
  },

  getReportList: (playerId) => {
    // console.log('Resource getReportList -----', playerId);
    if (debugStatus.getReportList) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${playerId}/reports`);
  },

  removeReport: (playerId, reportId, password) => {
    // console.log('Resource removeReport -----', playerId, reportId);
    if (debugStatus.removeReport) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/players/${playerId}/reports/${reportId}`, { data: { verify: password } });
  },

  getReport: (playerId, reportId) => {
    // console.log('Resource getReport -----', playerId);
    if (debugStatus.getReport) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${playerId}/reports/${reportId}`);
  },

  getTrainingAdvice: (playerId, data) => {
    // console.log('Resource getTrainingAdvice -----', playerId);
    if (debugStatus.getTrainingAdvice) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/players/${playerId}/training_advice`, { data });
  },

  createTrainingCalendar: (playerId, data) => {
    // console.log('Resource createTrainingCalendar -----', playerId);
    if (debugStatus.createTrainingCalendar) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/players/${playerId}/training_calendar`, { data });
  },

  createTraining: (playerId, data) => {
    // console.log('Resource createTraining -----', playerId);
    if (debugStatus.createTraining) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.post(`/api/players/${playerId}/trainings`, { data });
  },

  getTrainingList: (playerId) => {
    // console.log('Resource getTrainingList -----', playerId);
    if (debugStatus.getTrainingList) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${playerId}/trainings`);
  },

  getTraining: (playerId, trainingId) => {
    // console.log('Resource getTraining -----', playerId);
    if (debugStatus.getTraining) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/players/${playerId}/trainings/${trainingId}`);
  },

  updateTrainingMenu: (playerId, trainingId, date, data) => {
    // console.log('Resource getTraining -----', playerId);
    if (debugStatus.getTraining) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api/players/${playerId}/trainings/${trainingId}/${date}`, { data });
  },

  readTrainingMenu: (playerId, trainingId, date) => {
    // console.log('Resource getTraining -----', playerId);
    if (debugStatus.getTraining) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.put(`/api/players/${playerId}/trainings/${trainingId}/${date}/read`);
  },

  removeTraining: (playerId, trainingId, password) => {
    // console.log('Resource removeTraining -----', playerId, trainingId);
    if (debugStatus.removeTraining) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.delete(`/api/players/${playerId}/trainings/${trainingId}`, { data: { verify: password } });
  },

  getComparisonRaw: (dataType, { age, level, after, before }) => {
    const URL = `/api/comparison/${dataType}/raw?age=${age}&level=${level}&after=${after}&before=${before}`;
    return new Promise((resolve, reject) => {
      const records = [];
      const fetchData = (cursor) => {
        ApiService.get(!cursor ? URL : `${URL}&cursor=${cursor}`)
          .then(({ data: { records: newRecords, cursor: newCursor } }) => {
            records.push(...newRecords);
            if (newRecords.length > 0) {
              fetchData(newCursor);
              return;
            }

            resolve(records);
          })
          .catch((e) => {
            reject(e);
          });
      };
      fetchData();
    });
  },

  getComparisonScan: (dataType) => {
    const URL = `/api/comparison/${dataType}/scan`;
    return new Promise((resolve, reject) => {
      const records = [];
      const fetchData = (cursor) => {
        ApiService.get(!cursor ? URL : `${URL}?cursor=${cursor}`)
          .then(({ data: { records: newRecords, cursor: newCursor } }) => {
            records.push(...newRecords);
            if (newRecords.length > 0) {
              fetchData(newCursor);
              return;
            }

            resolve(records);
          })
          .catch((e) => {
            reject(e);
          });
      };
      fetchData();
    });
  },

  getComparisonTeam: (dataType, teamID) => {
    const URL = `/api/comparison/${dataType}/teams?teamID=${teamID}`;
    return ApiService.get(URL);
  },
};

export default PlayerResource;
