import React, { useRef } from 'react';
import styled from 'styled-components';
import { BsExclamationCircle } from 'react-icons/bs';
import { COMMON_COLOR } from '~~styles/_variables';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  ${InputStyle}

  .styleTextArea {
    min-height: 200px;
  }

  .fieldLabel {
    top: 10px;
  }
`;

const TextArea = (props) => {
  const { name, fieldLabel, value, className, onChange, placeholder, errorMsg, disabled, onBlur, onFocus } = props;
  const triggerField = useRef(null);

  function handleRemove(e) {
    e.preventDefault();
    onChange({ [name]: '' });
  }

  function handleChange(e) {
    const val = e.target.value;
    const key = e.target.name;

    onChange({ [key]: val });
  }

  function handleFocus() {
    onFocus(name);
  }

  function handleBlur() {
    onBlur();
  }

  function handleLabelClick() {
    triggerField.current.focus();
  }

  function getClassName() {
    let result = ['textAreaBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv className={getClassName()} fieldColor={props.fieldColor} fieldBgColor={props.fieldBgColor}>
      <textarea
        // {...props}
        ref={triggerField}
        id={`textarea--${name}`}
        name={name}
        value={value}
        className="styleTextArea"
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="new-password"
      />
      <label htmlFor={name} className="fieldLabel" onClick={handleLabelClick}>
        {fieldLabel}
      </label>
      {errorMsg && (
        <div className="error-box">
          <BsExclamationCircle className="error-box--icon" />
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

TextArea.defaultProps = {
  name: '',
  fieldLabel: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: '',
  errorMsg: '',
  disabled: false,
  className: '',
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
};

export default TextArea;
