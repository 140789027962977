import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'moment/locale/zh-tw';
import 'react-datetime/css/react-datetime.css';
import { BtnIcon } from '~~components/Buttons';
import { BsExclamationCircle, BsX } from 'react-icons/bs';
import { COMMON_COLOR } from '~~styles/_variables';
import { SketchPicker } from 'react-color';
import validateColor from 'validate-color';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  .current-color {
    margin-left: 6px;
    width: 40px;
    height: 40px;
    padding: 2px;
    border-radius: 2px;
    border: 1px solid #e1e1e1;
    background-color: ${(props) => (props.color && validateColor(props.color) ? props.color : 'inherit')};
  }
  .color-controller {
    position: relative;
    .sketch-picker {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
      margin: 8px 0 0;
      z-index: 2;
    }
  }
`;

const CustomizedColorPicker = (props) => {
  const {
    name,
    fieldLabel,
    value,
    className,
    onChange,
    placeholder,
    errorMsg,
    disabled,
    onBlur,
    onFocus,
    isValidDate,
  } = props;
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const triggerField = useRef(null);

  function handleRemove(e) {
    e.preventDefault();
    onChange({ [name]: '' });
  }

  function handleChange(val) {
    onChange({ [name]: val.hex });
  }

  function handleFocus() {
    onFocus(name);
    setIsReadOnly(false);
  }

  function handleBlur() {
    onBlur(name);
    setIsReadOnly(true);
  }

  function handleLabelClick() {
    triggerField.current.focus();
  }

  function getClassName() {
    let result = ['colorPickerBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv className={getClassName()} fieldColor={props.fieldColor} fieldBgColor={props.fieldBgColor} color={value}>
      <label>{fieldLabel}</label>
      <div className="current-color"></div>
      <div className="color-controller">
        <BtnIcon
          iconType={!isColorPickerOpen ? 'view' : 'X'}
          onClick={disabled ? () => {} : () => setIsColorPickerOpen(!isColorPickerOpen)}
        />
        {isColorPickerOpen && <SketchPicker color={value} onChange={handleChange} disableAlpha />}
      </div>
      {value && <BtnIcon iconType="delete" onClick={() => handleChange({ hex: '' })} />}
    </StyledDiv>
  );
};

CustomizedColorPicker.defaultProps = {
  fieldLabel: 'Label',
  name: '',
  value: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: '',
  errorMsg: '',
  disabled: false,
  className: '',
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
  isValidDate: () => true,
};

export default CustomizedColorPicker;
