import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COMMON_COLOR, respondSmallerThan } from '../../styles/_variables';
import { useLogin } from '../../hooks/useLogin';

const StyledNav = styled.div`
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'absolute')};
  padding: 5px 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  cursor: pointer;
  .hello {
    display: ${(props) => (props.isOpen ? 'none' : 'block')};
    ${(props) => (props.darkBG ? 'color: white;' : '')}
  }
  background: ${(props) => (props.isOpen ? 'unset' : props.darkBG ? `${COMMON_COLOR.BOARD_BLUE_1}66` : '#f0f2f4')};
  border-radius: 12px;

  ${respondSmallerThan.mobile_480`
    .hello {
      display: none;
    }
  `}
`;
const StyledBurger = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(20px)' : 'translateX(0px)')};
  padding: 5px 10px;

  div {
    width: 26px;
    height: 3px;
    background: ${({ isOpen, isLeaderboard }) =>
      isOpen ? COMMON_COLOR.WHITE : isLeaderboard ? COMMON_COLOR.BOARD_BLUE_1 : COMMON_COLOR.BRAND_2};
    transition: all 0.4s linear;
    position: relative;
    transform-origin: 1px;
    border-radius: 4px;
    &:not(:last-child) {
      margin: 0 0 6px;
    }

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'translateY(0px) rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      width: ${({ isOpen }) => (isOpen ? '26px' : '20px')};
      transform: ${({ isOpen }) => (isOpen ? 'translateY(8px) rotate(-45deg)' : 'rotate(0)')};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'translateY(0px) rotate(-45deg)' : 'rotate(0)')};
    }
  }

  ${respondSmallerThan.tablet_1024`
    transform: unset
  `}
`;

const HamburgerIcon = (props) => {
  const { isOpen, setIsOpen } = props;
  const locationPath = useLocation().pathname;
  const isLeaderboard = locationPath === '/leaderboard';

  const { isLogin, userInfo } = useLogin();

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen((prev) => !prev);
  }

  return (
    <StyledNav className="iconNav" darkBG={isLeaderboard} isOpen={isOpen} onClick={(e) => handleClick(e)}>
      <span className="hello">{!isLogin ? '登入' : userInfo.name}</span>
      <StyledBurger isOpen={isOpen} isLeaderboard={isLeaderboard}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </StyledNav>
  );
};

export default HamburgerIcon;
