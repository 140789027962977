import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { AuthResource } from '~~apis/resource';
import { Button, BntIcon } from '~~components/Buttons';
import { Field, Input } from '~~components/Fields';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '~~components/Modals';
import { getChartFontSize, setChartFontSize } from '~~utils/display';
import { respondSmallerThan, TOAST_SUCCESS, TOAST_FAIL } from '~~styles/_variables';

const StyleModal = styled(Modal)`
  .modal-content {
    .modal-body {
      padding: 40px 100px;
    }
    .fieldBox {
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }
  ${respondSmallerThan.laptop_1280`
    top: 12%;
  `}
  ${respondSmallerThan.laptop_1280`
    top: 18%;
  `}
  ${respondSmallerThan.mobile_480`
   .modal-content{
    .modal-body{
      padding: 8% 10%;
    }
  }
  `}
`;

const PreferenceModal = (props) => {
  const { isOpen, toggleClose, setLoaderStatus } = props;

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (isOpen) return;
  }, [isOpen]);

  return (
    <StyleModal isOpen={isOpen} modalSize="small">
      <ModalHeader>偏好設定</ModalHeader>
      <ModalBody>
        <Field>
          <span>圖表字體大小</span>
          <BntIcon
            key={`${refreshKey}-minus`}
            iconType="minus"
            disabled={getChartFontSize() <= 1}
            onClick={() => {
              setChartFontSize(getChartFontSize() - 1);
              setRefreshKey(refreshKey + 1);
            }}
          />
          <span key={`${refreshKey}-size`} style={{ fontSize: `${getChartFontSize()}px` }}>
            {getChartFontSize()}
          </span>
          <BntIcon
            key={`${refreshKey}-add`}
            iconType="add"
            onClick={() => {
              setChartFontSize(getChartFontSize() + 1);
              setRefreshKey(refreshKey + 1);
            }}
          />
        </Field>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggleClose}>返回</Button>
      </ModalFooter>
    </StyleModal>
  );
};

export default PreferenceModal;
