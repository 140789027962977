export const getChartFontSize = () => {
  const chartFontSize = localStorage.getItem('chartFontSize');
  if (!chartFontSize) {
    return 10;
  }

  return parseInt(chartFontSize);
};

export const setChartFontSize = (size) => {
  localStorage.setItem('chartFontSize', size.toString());
};
