import moment from 'moment';
import { fakeApiHelper } from '../../utils/_commonUtils';
import ApiService from '../ApiService';
import ObjectID from 'bson-objectid';

const debugStatus = {
  getTrainingDrills: false,
  getTrainingTreeView: false,
  updateTrainingTreeView: false,
  getTrainingDrillSet: false,
  getTrainingDrillTemplate: false,
  createDrillSet: false,
};

const TrainingResource = {
  getTrainingDrills: (trainingType, trainingStage) => {
    // console.log('Resource getTrainingDrills -----', data)
    if (debugStatus.getTrainingDrills) {
      return fakeApiHelper(800, 'success', {
        status: 200,
      });
    }
    return ApiService.get(`/api/training/drills?training_type=${trainingType}&training_stage=${trainingStage}`);
  },

  getTrainingVeloGlobal: () => {
    return ApiService.get(`/api/training_velo/global`);
  },

  getTrainingVeloPersonal: ({ playerID }) => {
    return ApiService.get(`/api/training_velo/personal?player=${playerID}`);
  },

  getTrainingTreeView: () => {
    if (debugStatus.getTrainingTreeView) {
      return fakeApiHelper(800, 'success', _getMockTreeView());
    }
    return ApiService.get(`/api/training/treeview`);
  },

  updateTrainingTreeView: ({ contentType, treeData }) => {
    if (debugStatus.updateTrainingTreeView) {
      return fakeApiHelper(800, 'success', _getMockTreeView());
    }
    return ApiService.put(`/api/training/treeview`, { data: { contentType, treeData } });
  },

  getTrainingDrillSet: ({ setID }) => {
    if (debugStatus.getTrainingDrillSet) {
      return fakeApiHelper(800, 'success', {
        ..._getMockDrillSet(),
        id: setID,
      });
    }
    return ApiService.get(`/api/training/drillsets/${setID}`);
  },
  getTrainingDrillSets: (setIDs) => {
    return ApiService.get(`/api/training/drillsets?ids=${setIDs.join('&ids=')}`);
  },

  getTrainingDrillTemplate: ({ templateID }) => {
    if (debugStatus.getTrainingDrillTemplate) {
      return fakeApiHelper(800, 'success', {
        id: templateID,
        name: 'MOCK 樣板組合',
        folders: ['投球', '進階'],
        days: [[_getMockDrillSet(), _getMockDrillSet()], [], [_getMockDrillSet(), _getMockDrillSet()]],
      });
    }
    return ApiService.get(`/api/training/drilltemplates/${templateID}`);
  },

  createDrillSet: ({ data }) => {
    return ApiService.post(`/api/training/drillsets`, { data });
  },

  updateDrillSet: ({ id, data }) => {
    return ApiService.put(`/api/training/drillsets/${id}`, { data });
  },

  deleteDrillSets: (setIDs, password) => {
    return ApiService.delete(`/api/training/drillsets?ids=${setIDs.join('&ids=')}`, { data: { verify: password } });
  },

  createDrillTemplate: ({ data }) => {
    return ApiService.post(`/api/training/drilltemplates`, { data });
  },

  updateDrillTemplate: ({ id, data }) => {
    return ApiService.put(`/api/training/drilltemplates/${id}`, { data });
  },

  deleteDrillTemplates: (templateIDs, password) => {
    return ApiService.delete(`/api/training/drilltemplates?ids=${templateIDs.join('&ids=')}`, {
      data: { verify: password },
    });
  },

  getPlayerCalendar: (playerID, after, before, dataType = '') => {
    const dayAfter = moment(after);
    const dayBefore = moment(before);
    return ApiService.get(
      `/api/players/${playerID}/calendar` +
        `?after=${dayAfter.format('YYYY-MM-DD')}&before=${dayBefore.format('YYYY-MM-DD')}` +
        (dataType ? `&dataType=${dataType}` : ''),
    );
  },
  createPlayerCalendarDrillSets: (playerID, drillSets) => {
    return ApiService.post(`/api/players/${playerID}/calendar`, { data: drillSets });
  },
  updatePlayerCalendarDrillSet: (playerID, playerDrillSet) => {
    return ApiService.put(`/api/players/${playerID}/calendar/${playerDrillSet.id}`, { data: playerDrillSet });
  },
  deletePlayerCalendarDrillSet: (playerID, playerDrillSet) => {
    return ApiService.delete(`/api/players/${playerID}/calendar/${playerDrillSet.id}`);
  },
  updatePlayerCalendarFeedback: (playerID, feedback) => {
    return ApiService.put(`/api/players/${playerID}/calendar_feedbacks/${moment(feedback.date).format('YYYY-MM-DD')}`, {
      data: feedback,
    });
  },
};

export default TrainingResource;

const _getMockTreeView = () => ({
  SETS: [
    {
      name: '投球',
      children: [
        {
          name: '基礎',
          children: [
            { id: '647602407ead713916600cf9', name: '混和A' },
            { id: '64760262264d96b4c1816e3a', name: '混和B' },
            { id: '6476026f6cf66b980f99da17', name: '恢復' },
            { id: '647602772acfc5fa7c8745f8', name: '投擲A' },
          ],
        },
        {
          name: '進階',
          children: [
            { id: '647602d6d4fe85e0417cbec4', name: '混和A' },
            { id: '647602d95e530d103079281c', name: '混和B' },
            { id: '647602dc4ebd847b15a2ad3d', name: '恢復' },
            { id: '647602dfc342d5e1b91d660a', name: '投擲A' },
          ],
        },
      ],
    },
    {
      name: '打擊',
      children: [
        {
          name: '基礎',
          children: [
            { id: '647602ee38a36f741a5d7f75', name: '混和A' },
            { id: '647602f2dc9fe99e3535f829', name: '混和B' },
            { id: '647602f5083dfc52fb54082b', name: '恢復' },
            { id: '647602f8dc772ba2814df064', name: '打擊A' },
          ],
        },
        {
          name: '進階',
          children: [
            { id: '64760305892c562ff668cde5', name: '混和A' },
            { id: '647603086a6db50bae090302', name: '混和B' },
            { id: '6476030ca4cb145e41053216', name: '恢復' },
            { id: '6476030eca944e004c5331ab', name: '打擊A' },
          ],
        },
      ],
    },
    {
      name: '體能',
      children: [
        {
          name: '基礎',
          children: [
            { id: '647603186908f9dc08f1f381', name: '混和A' },
            { id: '6476031bbbcb07a7ec7c4fe4', name: '混和B' },
            { id: '6476031e5b72d5d1d0db8f80', name: '恢復' },
            { id: '64760321c9f12760ef5310eb', name: '體能A' },
          ],
        },
        {
          name: '進階',
          children: [
            { id: '6476032e03586b5eac3d0f5c', name: '混和A' },
            { id: '64760331a4ecde4888be14b9', name: '混和B' },
            { id: '64760334d0391ffda2e317a3', name: '恢復' },
            { id: '64760337483b7afea08845fa', name: '體能A' },
          ],
        },
      ],
    },
    {
      name: 'Jesse 自訂',
      children: [{ id: '6476035b2db49bd2f056b332', name: '速度期' }],
    },
    {
      name: 'Wayne 自訂',
      children: [{ id: '647603622d1992aa646f7211', name: '加速期' }],
    },
  ],
  TEMPLATES: [
    {
      name: '投球',
      children: [
        {
          name: '兩周',
          children: [
            { id: '647614d5b28333ea29f4a792', name: '混和' },
            { id: '647614d8d4d23a09a2beda25', name: '投擲' },
          ],
        },
        {
          name: '一個月',
          children: [
            { id: '647614de7c4a098411ded300', name: '混和' },
            { id: '647614e1777ad3d52ea1ec81', name: '投擲' },
          ],
        },
      ],
    },
    {
      name: '打擊',
      children: [
        {
          name: '兩周',
          children: [
            { id: '647614ebc0c258f9ac06f993', name: '混和' },
            { id: '647614ef43baca348f4ef4e2', name: '打擊' },
          ],
        },
        {
          name: '一個月',
          children: [
            { id: '647614f554d4f7741dec01f6', name: '混和' },
            { id: '647614f85633a95bd2b63b57', name: '打擊' },
          ],
        },
      ],
    },
  ],
});

const _getMockDrillSet = () => ({
  id: ObjectID(),
  name: 'MOCK 訓練組合',
  folders: ['打擊', '進階'],
  drills: [
    {
      name: '三種面向打擊',
      bats: '加重減輕棒',
      balls: '棒球',
      env: 'T/前拋',
      set: '1',
      rep: '12',
      strength: '60-70%',
      videoLink: '',
    },
    {
      name: '前腳交叉打擊',
      bats: '正常球棒',
      balls: '棒球',
      env: 'T/前拋',
      set: '1',
      rep: '10',
      strength: '60-70%',
      videoLink: '',
    },
  ],
});
