import React from 'react';
import styled from 'styled-components';

const StyleField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Field = (props) => {
  const { className } = props;

  function getClassName() {
    let result = ['fieldBox'];
    if (className) {
      result.push(className);
    }

    return result.join(' ');
  }
  return (
    <StyleField {...props} className={getClassName()}>
      {props.children}
    </StyleField>
  );
};

Field.defaultProps = {
  children: null,
  className: '',
};

export default Field;
