import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { COMMON_COLOR } from '~~styles/_variables';
import InputStyle from './InputStyle';

const StyledDiv = styled.div`
  ${InputStyle}
`;

const PasswordInput = (props) => {
  const { name, fieldLabel, value, className, onChange, placeholder, errorMsg, disabled, onBlur, onFocus } = props;
  const [isShow, setIsShow] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const triggerField = useRef(null);

  function handleChange(e) {
    const val = e.target.value;
    const key = e.target.name;

    onChange({ [key]: val });
  }

  function handlePwShow(e, status) {
    e.preventDefault();
    setIsShow(status);
    triggerField.current.focus();
  }

  function handleFocus() {
    onFocus(name);
    setIsReadOnly(false);
  }

  function handleBlur() {
    onBlur(name);
    setIsReadOnly(true);
  }

  function handleLabelClick() {
    triggerField.current.focus();
  }

  function getClassName() {
    let result = ['inputBox'];
    if (className) {
      result.push(className);
    }
    if (errorMsg) {
      result.push('isError');
    }
    if (disabled) {
      result.push('disabled');
    }

    return result.join(' ');
  }

  return (
    <StyledDiv className={getClassName()} fieldColor={props.fieldColor} fieldBgColor={props.fieldBgColor}>
      <input
        // {...props}
        ref={triggerField}
        type={isShow ? 'text' : 'password'}
        id={`input--${name}`}
        name={name}
        value={value}
        className="styleInput"
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="new-password"
        // readOnly={isReadOnly}
      />
      <label htmlFor={name} className="fieldLabel" onClick={handleLabelClick}>
        {fieldLabel}
      </label>
      {isShow ? (
        <div className="btnFunc" onMouseDown={(e) => handlePwShow(e, false)}>
          <BsEye viewBox="-3 -3 22 22" />
        </div>
      ) : (
        <div className="btnFunc" onMouseDown={(e) => handlePwShow(e, true)}>
          <BsEyeSlash viewBox="-3 -3 22 22" />
        </div>
      )}
      {errorMsg && (
        <div className="errorBox">
          {/* <BsExclamationCircle className='icon' /> */}
          {errorMsg}
        </div>
      )}
    </StyledDiv>
  );
};

PasswordInput.defaultProps = {
  fieldLabel: '密碼',
  name: '',
  value: '',
  className: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  placeholder: '',
  fieldColor: COMMON_COLOR.BLACK,
  fieldBgColor: COMMON_COLOR.WHITE,
};

export default PasswordInput;
